import classNames from "classnames/bind";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NavHorizontal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useSosAuth } from "../../../hooks/auth/useSosAuth";
import { useQSOS_Profile } from "../../../hooks/queries/user/useQSOS_Profile";
import { SHrSosTabsBar } from "@simplyhomes/react";

const cx = classNames.bind(styles);

const tabs = ["All notifications", "Not Read"];

const NavHorizontal = () => {
   const nav = useNavigate();

   const { logOut } = useSosAuth();

   const qUserProfile = useQSOS_Profile();

   const [activeTab, setActiveTab] = useState("All notifications");

   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

   const [isShowNotificationOpen, setIsShowNotificationOpen] = useState(false);

   const { data: userProfile } = qUserProfile;

   const profile = userProfile?.profile;
   if (!profile) return null;

   const { picture } = profile;

   const userMenuItems: { icon: string; title: string; cb: () => void }[] = [
      { icon: "person_primary.svg", title: "My Profile", cb: () => nav("/profile") },
      { icon: "settings_primary.svg", title: "Account settings", cb: () => nav("/account-settings") },
      { icon: "help_center_primary.svg", title: "Support center", cb: () => nav("/support-center") },
      { icon: "exit_to_app_primary.svg", title: "Sign out", cb: logOut },
   ];

   const notificationData: TNotification[] = [
      {
         id: 1,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 2,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 2,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 3,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 4,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 5,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 6,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 7,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 8,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 9,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 10,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 11,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 12,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 13,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 14,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 15,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 16,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 17,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 18,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 19,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 20,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 21,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 22,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 23,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 24,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 25,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 26,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 27,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 28,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: false,
      },
      {
         id: 29,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
      {
         id: 30,
         message: "You have a new message from John Doe",
         time: "2 minutes ago",
         user: { id: 1, name: "John Doe", avatar: picture },
         cb: () => console.log("click"),
         notRead: true,
      },
   ];

   return (
      <div className={cx("contentC")}>
         <div className={cx("logo")}>
            <img src={CF_CDN_URL("/assets/nav_logo.svg")} alt="" />
            <span>Simply OS</span>
         </div>

         <div className={cx("notification")}>
            <div className={cx("img-icon")} onClick={() => setIsShowNotificationOpen(!isShowNotificationOpen)}>
               <img src={CF_CDN_URL("/assets/notifications_snow.svg")} alt="" />
            </div>
            <div className={cx("avt-notification")} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
               <div>{profile?.picture && <img src={picture} alt="" />}</div>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/arrow_drop_down.svg")} alt="" />
               </div>
            </div>

            <div
               className={cx("menu-item-notication", {
                  open: isShowNotificationOpen,
                  notification: isShowNotificationOpen,
               })}
               onMouseLeave={() => setIsDropdownOpen(false)}
               onClick={() => setIsDropdownOpen(false)}
            >
               <div className={cx("topC")}>
                  <span className={cx("heading")}>Notifications</span>
                  <span className={cx("mark")}>Mark all as read</span>
               </div>
               <div className={cx("tabs")}>
                  <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={(v) => setActiveTab(v)} />
               </div>
               {activeTab && activeTab === "All notifications" && (
                  <div className={cx("listItemsC")}>
                     {notificationData.map((item, index) => (
                        <Notification key={item.id + index} item={item} />
                     ))}
                  </div>
               )}
               {activeTab && activeTab === "Not Read" && (
                  <div className={cx("listItemsC")}>
                     {notificationData
                        .filter((i) => i.notRead)
                        .map((item, index) => (
                           <Notification key={item.id + index} item={item} />
                        ))}
                  </div>
               )}
            </div>

            <ul
               className={cx("menu-item", { open: isDropdownOpen, user: isDropdownOpen })}
               onMouseLeave={() => setIsDropdownOpen(false)}
               onClick={() => setIsDropdownOpen(false)}
            >
               {userMenuItems.map((item, index) => (
                  <li key={index + item.title} onClick={item.cb}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL(`/assets/${item.icon}`)} alt="" />
                     </div>
                     <span>{item.title}</span>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
};

type NotificationProps = {
   item: TNotification;
};

const Notification = ({ item }: NotificationProps) => {
   return (
      <div onClick={item.cb} className={cx("itemC")}>
         <div className={cx("img-icon-avatar")}>
            <img src={item.user.avatar} alt="" />
         </div>
         <div className={cx("messages")}>
            <span>{item.message}</span>
            <span className={cx("time")}>{item.time}</span>
         </div>

         {item.notRead && <div className={cx("not-read")} />}
      </div>
   );
};

export default NavHorizontal;

type TUser = {
   id: number;
   name: string;
   avatar: string;
};
type TNotification = {
   id: number;
   message: string;
   time: string;
   user: TUser;
   cb: () => void;
   notRead?: boolean;
};
