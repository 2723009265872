import classNames from "classnames/bind";
import styles from "./SoSConvoPostCommentModal.module.scss";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useState } from "react";
import { SHu_js_timeSince } from "@simplyhomes/utils";

import { Mention, MentionsInput } from "react-mentions";
import { useAppContext } from "../../../contexts/AppContext";
import { useQConvo_Threads } from "../../../hooks/conversation-api/queries/threads/useQConvo_Threads";
import {
   TUseQCon_getCommentsInThead_Comment,
   useQConvo_Comments,
} from "../../../hooks/conversation-api/queries/threads/useQConvo_Comments";
import { useQ_Users } from "../../../hooks/queries/user/useQ_Users";
import { useMConvo_CreateComment } from "../../../hooks/conversation-api/mutates/threads/useMConvo_CreateComment";
import { useMConvo_ToggleThreadSubscription } from "../../../hooks/conversation-api/mutates/threads/useMConvo_ToggleThreadSubscription";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
const cx = classNames.bind(styles);

export const SoSConvoPostCommentModal = ({ tid, onClose }: TSoSConvoPostCommentModalProps) => {
   const { appState } = useAppContext();

   const { currentUser } = appState;

   const { picture } = currentUser;

   const [mention, setMention] = useState("");

   const qConvoGetThreads = useQConvo_Threads();

   const qConvoGetCommentInThread = useQConvo_Comments({ tid });
   const { comments } = useQConvo_Comments({ tid });

   const qUsers = useQ_Users();

   const mConvo_CreateComment = useMConvo_CreateComment({ tid });
   const mConvo_ToggleSub = useMConvo_ToggleThreadSubscription();

   if (!qConvoGetThreads.data) return null;
   const { threadsMap } = qConvoGetThreads;

   const thread = threadsMap[tid];
   if (!thread) return null;

   const { creator, created_at, title, content, comment_count, subscribed } = thread;

   const timestamp = new Date(created_at).getTime();

   const handleCreateNewComment = () => {
      setMention("");
      mConvo_CreateComment.mutate({ content: mention });
   };
   const handleSubscribe = () => mConvo_ToggleSub.mutate({ tid: thread.tid, subscribed: !subscribed });

   const users = qUsers.data?.users.map((user) => ({ id: user.uid, display: user.name }));

   const handleMention = (name: string, uid: string) => {
      const mentionExists = mention.includes(`@[${name}]`);
      if (!mentionExists) {
         setMention(`@[${name}](${uid})`);
      }
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("userInfoC")}>
               <div className={cx("avt")}>
                  <img src={creator.picture} alt="" />
               </div>
               <div className={cx("nameC")}>
                  <span>
                     <b>{creator.name}</b>
                  </span>
                  <span className={cx("role")}>{creator.roles}</span>
               </div>
            </div>
            <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>
            <SHrSosButton
               type="text"
               loading={mConvo_ToggleSub.isPending}
               buttonProps={{ className: cx("btn"), onClick: () => handleSubscribe() }}
            >
               {subscribed ? "Unsubscribe" : "Subscribe"}
            </SHrSosButton>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("contentC")}>
            <div className={cx("postC")}>
               <div className={cx("post")}>
                  <span className={cx("title")}>{title}</span>
                  <p>{content}</p>
               </div>
               <hr className={cx("divider")} />
               <span className={cx("numberComs")}>
                  {comment_count} {comment_count > 1 ? "comments" : "comment"}
               </span>
            </div>
            <div className={cx("commentGroupC")}>
               {qConvoGetCommentInThread.isLoading ? (
                  <div className={cx("spinnerC")}>
                     <SHrSpinner />
                     Loading...
                  </div>
               ) : (
                  <div className={cx("commentList")}>
                     {comments.map((comment, i) => (
                        <Comment key={i} comment={comment} handleMention={handleMention} />
                     ))}
                     <span
                        className={cx("load-more", {
                           hidden: !qConvoGetCommentInThread.hasNextPage || qConvoGetCommentInThread.isFetchingNextPage,
                        })}
                        onClick={() => qConvoGetCommentInThread.fetchNextPage()}
                     >
                        Load More
                     </span>
                  </div>
               )}
            </div>
         </div>
         <div className={cx("postCommentC")}>
            <div className={cx("avt")}>
               <img src={picture} alt="" />
            </div>
            {/* <div className={cx("input_search")}>
               <SoSInputTitle
                  value={comment}
                  type="text"
                  handleValue={(value) => setComment(value)}
                  placeholder="Enter your comment"
               />
            </div> */}

            <div className={cx("input_search")}>
               <MentionsInput
                  placeholder="Enter your comment"
                  value={mention}
                  onChange={(e) => setMention(e.target.value)}
                  className={cx("inputMentionC")}
               >
                  <Mention
                     data={users!}
                     trigger={"@"}
                     className={cx("mentionC")}
                     displayTransform={(id, display) => `@${display}`}
                  />
               </MentionsInput>
            </div>

            <SHrSosButton
               buttonProps={{
                  className: cx("btn"),
                  disabled: !mention,
                  onClick: () => handleCreateNewComment(),
               }}
               loading={mConvo_CreateComment.isPending}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/conversation/arrow_upward_black.svg")} alt="" />
               </div>
            </SHrSosButton>
         </div>
      </div>
   );
};
type TSoSConvoPostCommentModalProps = {
   onClose: () => void;
   tid: number;
};

const handleString = (input: string) => {
   const regex = /@\[(.*?)\]\(\d+\)/g;
   const parts = input.split(regex);

   return (
      <span>
         {parts.map((part, index) => {
            if (index % 2 === 1) {
               return (
                  <span key={index} style={{ color: "blue" }}>
                     @{part}
                  </span>
               );
            }
            return part;
         })}
      </span>
   );
};

const Comment = ({ comment, handleMention }: TPViCommentInThreadProps) => {
   const { commentor, content } = comment;
   const timestamp = new Date(comment.created_at).getTime();

   return (
      <div className={cx("commentC")}>
         <div className={cx("avt")}>
            <img src={commentor.picture} alt="" />
         </div>

         <div className={cx("comment")}>
            <div className={cx("info")}>
               <span>
                  <b>{commentor.name}</b>
               </span>
               <p>{commentor.roles}</p>
            </div>
            {handleString(content)}
            <div className={cx("reply")} onClick={() => handleMention(commentor.name, commentor.uid)}>
               Reply
            </div>
         </div>

         <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>

         <div className={cx("img-icon")}>
            <img src={CF_CDN_URL("/assets/property_viability/more_horiz_neutral500.svg")} alt="" />
         </div>
      </div>
   );
};
type TPViCommentInThreadProps = {
   handleMention: (name: string, uid: string) => void;
   comment: TUseQCon_getCommentsInThead_Comment;
};

