import { useInfiniteQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useMemo } from "react";
import useDebounce from "../../../react/useDebounce";
import { useConvoContext } from "../../../../contexts/ConvoContext";
export const useQConvo_Threads = () => {
   const { sosAxios } = useSosAxios();

   const { convoState } = useConvoContext();
   const { lid, pid, q, tags } = convoState;

   const dbQ = useDebounce(q, 500);

   const baseParams = { limit: 5000, lid, pid, q: dbQ, tags: tags };

   const query = useInfiniteQuery({
      queryKey: [`useQCon_getThreadsByPropertyId`, baseParams],
      staleTime: 500,
      queryFn: ({ pageParam: offset }) =>
         sosAxios
            .get(`/conversations/threads/`, { params: { ...baseParams, offset } })
            .then<TUseQPVi_Threads_Data>(({ data }) => {
               const threads = data.payload.threads;
               const threadsMap = threads.reduce(
                  (acc: Record<string, TUseQCon_getThreadsByPropertyId>, thread: TUseQCon_getThreadsByPropertyId) => {
                     acc[thread.tid] = thread;
                     return acc;
                  },
                  {} as Record<string, TUseQCon_getThreadsByPropertyId>
               );

               return {
                  payload: {
                     threads,
                     threadsMap,
                     threadIds: threads.map((thread: TUseQCon_getThreadsByPropertyId) => thread.tid),
                  },
                  metadata: data.metadata,
               };
            }),
      initialPageParam: 0,
      getNextPageParam: ({ metadata: { nextOffset } }) =>
         nextOffset > 0 && nextOffset >= +baseParams.limit ? nextOffset : undefined,
   });
   const threads = useMemo(() => query.data?.pages.flatMap((page) => page.payload.threads) || [], [query.data?.pages]);
   const threadsMap = useMemo(
      () =>
         query.data?.pages.reduce(
            (acc, page) => ({ ...acc, ...page.payload.threadsMap }),
            {} as Record<string, TUseQCon_getThreadsByPropertyId>
         ) || {},
      [query.data?.pages]
   );

   return { ...query, threads, threadsMap };
};
type TUseQPVi_Threads_Data = {
   payload: TUseQCon_getThreadsByPropertyId_Response;
   metadata: { totalRows: number; nextOffset: number };
};

export type TUseQCon_getThreadsByPropertyId = {
   tid: number;
   title: string;
   content: string;
   created_at: string;
   subscribed: boolean;
   comment_count: number;
   creator: TUseQCon_getThreadsByPropertyId_User;
   tags: string[];
};
export type TUseQCon_getThreadsByPropertyId_Response = {
   threads: TUseQCon_getThreadsByPropertyId[];
   threadsMap: Record<string, TUseQCon_getThreadsByPropertyId>;
   threadIds: string[];
};

export type TUseQCon_getThreadsByPropertyId_User = {
   uid: number;
   name: string;
   picture: string;
   roles: string[];
};
