import classNames from "classnames/bind";
import { useState } from "react";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";

import styles from "./SoSConversation.module.scss";

import { SoSConvoThreadCard } from "./SoSConvoThreadCard/SoSConvoThreadCard";
import { SoSConvoCreateNewThreadModal } from "./SoSConvoCreateNewThreadModal/SoSConvoCreateNewThreadModal";
import { ConvoProvider, ConvoState, useConvoContext } from "../../contexts/ConvoContext";
import { useQConvo_Threads } from "../../hooks/conversation-api/queries/threads/useQConvo_Threads";
import { useSoSOptions_Convo_Thread_Tags } from "../../hooks/queries/options/useQSoS_Options";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { SoSInputTitle } from "../../components/common/soSInputTitle/SoSInputTitle";
import SoSFilter from "../../components/common/SoSFilter/SoSFilter";

const cx = classNames.bind(styles);
type SoSConversationProps = Pick<ConvoState, "lid"> | Pick<ConvoState, "pid">;
export const SoSConversation = (p: SoSConversationProps) => {
   return (
      <div className={cx("container", "_jr_pviConversation")}>
         <ConvoProvider initialState={p}>
            <Conversation />
         </ConvoProvider>
      </div>
   );
};

const Conversation = () => {
   const { convoState, convoDispatch } = useConvoContext();
   const { q, tags } = convoState;

   const [isOpenCreateNewThreadModal, setIsOpenCreateNewThreadModal] = useState(false);

   const qPViConvGetThreads = useQConvo_Threads();

   const { threads = [] } = qPViConvGetThreads;
   const qGetConvoTags = useSoSOptions_Convo_Thread_Tags();

   if (!qGetConvoTags.data?.options) return null;
   const tagOptions = qGetConvoTags.data?.options.map((o) => ({
      label: o.label,
      value: o.value,
   }));

   const tagList = tagOptions.filter((o) => tags.includes(o.value)).map((o) => o.label);

   const handleRemoveTag = (i: number) => {
      const newTags = tags.filter((_, index) => index !== i);
      convoDispatch({ overwrite: { tags: newTags } });
   };

   return (
      <div className={cx("conversationC")}>
         <div className={cx("btnNotifyAndSlack")}>
            <SHrSosButton
               buttonProps={{ className: cx("btn", "white"), onClick: () => setIsOpenCreateNewThreadModal(true) }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/edit_black.svg")} alt="" />
               </div>
               New thread
            </SHrSosButton>

            {/* <SHrSosButton type="elevated" buttonProps={{ className: cx("btn"), onClick: () => {} }}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/social_slack.svg")} alt="" />
               </div>
               Link to Slack
            </SHrSosButton> */}
         </div>
         <div className={cx("input_search")}>
            <SoSInputTitle
               value={q}
               type="text"
               handleValue={(q) => convoDispatch({ overwrite: { q } })}
               icon="search"
               placeholder="Search in conversation"
            />
         </div>
         <div className={cx("filterConversationC")}>
            <div className={cx("filterC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/local_offer_black.svg")} alt="" />
               </div>
               <div className={cx("input-search")}>
                  <SoSFilter
                     title="Filter"
                     isMulti
                     value={tags}
                     onChange={(op) => convoDispatch({ overwrite: { tags: op.map((o) => o.value) } })}
                     options={tagOptions}
                  />
               </div>

               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>

               <div className={cx("tagsC")}>
                  {tagList.map((t, i) => (
                     <div className={cx("filterTag")} key={i}>
                        <span>{t}</span>
                        <div className={cx("img-icon")} onClick={() => handleRemoveTag(i)}>
                           <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>

         <div className={cx("threadsC")}>
            {qPViConvGetThreads.isLoading ? (
               <div className={cx("spinnerC")}>
                  <SHrSpinner />
                  Loading...
               </div>
            ) : threads.length === 0 ? (
               <div className={cx("noThread")}>No thread</div>
            ) : (
               threads.map((thread, i) => <SoSConvoThreadCard key={i} tid={thread.tid} />)
            )}
         </div>

         <SHrPopup show={isOpenCreateNewThreadModal} handleShow={(s) => setIsOpenCreateNewThreadModal(s)}>
            <SoSConvoCreateNewThreadModal onClose={() => setIsOpenCreateNewThreadModal(false)} />
         </SHrPopup>
      </div>
   );
};
