import classNames from "classnames/bind";
import styles from "./PViUnderwriting.module.scss";
import { SoSDataPoint } from "../../../../../components/SoSDataPoint/SoSDataPoint";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";
import { useMDatabase_UpdateValue } from "../../../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { useState } from "react";
import { SoSRoudedTabs } from "../../../../../components/common/SoSRoudedTabs/SoSRoudedTabs";
import { PViAddNewModelModal } from "../../PViAddNewModelModal/PViAddNewModelModal";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { PgSoS_SoSProperties_UnderwritingModel } from "@simplyhomes/utils/dist/types/pg/sos/properties";
import { PViHCVRentTab } from "./PViHCVRentTab/PViHCVRentTab";
import { PViCustomRent } from "./PViCustomRent/PViCustomRent";

const cx = classNames.bind(styles);

export const PViUnderwriting = () => {
   const qPVi_Property = useQPVi_Property();

   const { property: p } = qPVi_Property.data || {};

   const db_pid = { column: "pid", id: p?.pid || "" };

   const [activeTab, setActiveTab] = useState("HCV rent");

   const [isOpenCreateRentTypeModal, setIsOpenCreateRentTypeModal] = useState(false);

   const db_P = dbcb("sos_properties", [db_pid], qPVi_Property.refetch);

   const mDb_P_dataVerification = useMDatabase_UpdateValue(db_P("pgcalc_max_prices"));

   const mDb_P_recalc = useMDatabase_UpdateValue(db_P("pgcalc_underwriting_models"));

   const mDb_P_uwModels = useMDatabase_UpdateValue(db_P("underwriting_models"));

   if (!p) return null;
   const models = p.underwriting_models?.reduce(
      (prev, model, index) => ({ ...prev, [model.name]: { ...model, index } }),
      {} as Record<string, (PgSoS_SoSProperties_UnderwritingModel & { index: number }) | undefined>
   );
   const selectedModel = models?.[activeTab];
   const handleDeleteModel = () => {
      if (!selectedModel) return;
      const newModels = p.underwriting_models?.filter((_, i) => i !== selectedModel.index);
      mDb_P_uwModels.mutate({ value: JSON.stringify(newModels) });
      setIsOpenCreateRentTypeModal(false);
      setActiveTab("HCV rent");
   };

   return (
      <div className={cx("container")}>
         <div className={cx("purchaseDetailsC")}>
            <span className={cx("heading")}>Purchase details</span>
            <div className={cx("infoC")}>
               <div className={cx("data")}>
                  <SoSDataPoint
                     title="Offer Price"
                     type="bold-value"
                     value={p.target_purchase_price_override || p.target_purchase_price}
                     format="currency"
                     database={db_P("target_purchase_price_override")}
                  />
               </div>

               <div className={cx("data")}>
                  <SoSDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={p.target_purchase_price_final}
                     title="Target purchase price"
                     isHistory
                  />
               </div>

               <div className={cx("data")}>
                  <SoSDataPoint
                     type={"bold-value"}
                     format="percentage"
                     value={p.arv_uplift / 100}
                     title="ARV uplift"
                     isHistory
                  />
               </div>

               <div className={cx("data")}>
                  <SoSDataPoint
                     type={"bold-value"}
                     format="percentage"
                     value={p.cap_rate / 100}
                     additionalInfor={"(HCV rent)"}
                     title="Cap rate"
                     isHistory
                  />
               </div>

               <div className={cx("data")}>
                  <SoSDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={p.max_price_arv_uplift}
                     title="Max price"
                     additionalInfor="(ARV uplift)"
                     isHistory
                     buttons={[
                        {
                           title: "refresh",
                           onClick: () => mDb_P_dataVerification.mutate({ value: true }),
                           loading: !!mDb_P_dataVerification.isMutating,
                        },
                     ]}
                  />
                  <SoSDataPoint
                     type={"bold-value"}
                     additionalInfor="(Cap rate)"
                     format="currency"
                     value={p.max_price_cap_rate}
                  />
               </div>
            </div>
         </div>
         <div className={cx("costC")}>
            <span className={cx("heading")}>Fees & Costs</span>
            <div className={cx("infoC")}>
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.all_in}
                  title="All-in cost"
                  isHistory
                  isMocked
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.renovation_cost}
                  title="Estimated Total Renovation Cost"
                  isHistory
               />
            </div>
            <div className={cx("infoNoBgC")}>
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.simply_acquisition_fee_final}
                  title="Simply Acquisition Fee"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.title_insurance_final}
                  title="Title insurance"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.transfer_tax_final}
                  title="Transfer tax"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.core_closing_cost}
                  title="Core closing costs"
                  isHistory
               />
            </div>
            <div className={cx("infoC")}>
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.estimated_acquisition_cost}
                  title="Estimated Acquisition Costs"
                  isHistory
               />
            </div>
            <div className={cx("infoNoBgC")}>
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.renovation_cost}
                  title="Renovation amount"
                  isHistory
                  database={db_P("renovation_cost")}
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.total_owner_reserves}
                  title="Owner Reserve amount"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.pm_reserve_total}
                  title="PM Reserve Amount"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={p.pm_onboarding_total}
                  title="PM Onboarding amount"
                  isHistory
               />
            </div>
         </div>

         <div className={cx("rentAndHAC")}>
            <div className={cx("tabsAndRecalculateC")}>
               <SoSRoudedTabs
                  tabs={["HCV rent", ...Object.keys(models || {})]}
                  value={activeTab}
                  onChange={(newTab) => {
                     setActiveTab(newTab);
                  }}
                  isCreated
                  onCreate={() => setIsOpenCreateRentTypeModal(true)}
               />

               <div className={cx("modelsBtn")}>
                  {!!selectedModel && (
                     <SHrSosButton
                        type="text"
                        buttonProps={{ className: cx("btn", "delete"), onClick: handleDeleteModel }}
                        loading={mDb_P_uwModels.isPending}
                     >
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
                        </div>
                        Delete Model
                     </SHrSosButton>
                  )}
                  <SHrSosButton
                     type="text"
                     buttonProps={{
                        className: cx("btn", "recalc"),
                        onClick: () => mDb_P_recalc.mutate({ value: true }),
                     }}
                     loading={!!mDb_P_recalc.isMutating}
                  >
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/property_viability/history_info500.svg")} alt="" />
                     </div>
                     Recalculate & Save
                  </SHrSosButton>
               </div>
            </div>

            {activeTab === "HCV rent" ? (
               <PViHCVRentTab />
            ) : !!selectedModel ? (
               <PViCustomRent modelIndex={selectedModel.index} />
            ) : null}
         </div>

         <SHrPopup show={isOpenCreateRentTypeModal}>
            <PViAddNewModelModal onClose={() => setIsOpenCreateRentTypeModal(false)} />
         </SHrPopup>
      </div>
   );
};
